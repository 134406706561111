import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import HotWaterSystemsIconWebp from '../img/hot-water-systems-icon.webp';
import HotWaterSystemsIconPng from '../img/hot-water-systems-icon.png';
import PlumbingMaintenanceIconWebp from '../img/plumbing-maintenance-icon.webp';
import PlumbingMaintenanceIconPng from '../img/plumbing-maintenance-icon.png';
import WaterLeaksIconWebp from '../img/burst-pipe-water-leak-icon.webp';
import WaterLeaksIconPng from '../img/burst-pipe-water-leak-icon.png';
import BathroomIconWebp from '../img/bathroom-renovation-icon.webp';
import BathroomIconPng from '../img/bathroom-renovation-icon.png';
import WaterFilterIconWebp from '../img/water-filter-icon.webp';
import WaterFilterIconPng from '../img/water-filter-icon.png';
import AboutIconWebp from '../img/about-us-icon.webp';
import AboutIconPng from '../img/about-us-icon.png';

const Nav = () => {

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLinkClick = () => {
    setMobileMenuOpen(false);
  };

  return (

    <nav>
      {/* Desktop Menu */}
      <ul className="desktop-menu-list desktop">
        <li>
          <a href="/services/hot-water-systems/">
            <span className='menu-icon'>
              <picture>
                <source type='image/webp' srcset={HotWaterSystemsIconWebp} />
                <source type='image/png' srcset={HotWaterSystemsIconPng} />
                <img src={HotWaterSystemsIconWebp} alt="Hot Water Systems icon" />
              </picture>
            </span>
            <span className='menu-text'>Hot Water Systems</span>
          </a>
        </li>
        <li>
          <a href="/services/plumbing-maintenance">
            <span className='menu-icon'>
              <picture>
                <source type='image/webp' srcset={PlumbingMaintenanceIconWebp} />
                <source type='image/png' srcset={PlumbingMaintenanceIconPng} />
                <img src={PlumbingMaintenanceIconWebp} alt="Plumbing Maintenance icon" />
              </picture>
            </span>
            <span className='menu-text'>Plumbing Maintenance</span>
          </a>
        </li>
        {/* <li>
          <a href="/services/water-filter-installation">
            <span className='menu-icon'>
              <picture>
                <source type='image/webp' srcset={WaterFilterIconWebp} />
                <source type='image/png' srcset={WaterFilterIconPng} />
                <img src={WaterFilterIconWebp} alt="Water Filter icon" />
              </picture>
            </span>
            <span className='menu-text'>Water Filter<br className='desktop' />Installation</span>
          </a>
        </li> */}
        <li>
          <a href="/#our-services">
            <span className='menu-icon'>
              <picture>
                <source type='image/webp' srcset={WaterLeaksIconWebp} />
                <source type='image/png' srcset={WaterLeaksIconPng} />
                <img src={WaterLeaksIconWebp} alt="Water Leaks icon" />
              </picture>
            </span>
            <span className='menu-text'>Water <br className='desktop' />Leaks</span>
          </a>
        </li>
        <li>
          <a href="/#our-services">
            <span className='menu-icon'>
              <picture>
                <source type='image/webp' srcset={BathroomIconWebp} />
                <source type='image/png' srcset={BathroomIconPng} />
                <img src={BathroomIconWebp} alt="Bathroom & Kitchen icon" />
              </picture>
              </span>
            <span className='menu-text'>Bathroom & Kitchen</span>
          </a>
        </li>
        <li>
          <a href="#about-us" className='call-btn-adjacent'>
            <span className='menu-icon'>
              <picture>
                <source type='image/webp' srcset={AboutIconWebp}/>
                <source type='image/png' srcset={AboutIconPng} />
                <img src={AboutIconWebp} alt="About Us icon" />
              </picture>
            </span>
            <span className='menu-text'>About Us</span>
          </a>
        </li>
        <li>
          <a href="tel:1300631226" className='call-btn'>
            <span className='menu-icon step'><FontAwesomeIcon icon={faPhone} size="lg" color="red" /></span>
            <span className='menu-text-sub'>Our plumbers are here to help</span>
            <span className='menu-text'>1300 631 226</span>
          </a>
        </li>
      </ul>

      {/* Mobile Menu */}
      <div className="mobile-menu mobile">
        <a href="tel:1300631226" className='call-btn'>
          <span className='menu-icon step'><FontAwesomeIcon icon={faPhone} size="lg" color="red" /></span>
          <span className='menu-text'>1300 631 226</span>
        </a>
        <button className="mobile-menu-toggle" onClick={handleMobileMenuToggle}>
          {isMobileMenuOpen ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faBars} /> }
        </button>
        {isMobileMenuOpen && (
          <div className='mobile-menu-list-holder'>
            <ul className="mobile-menu-list">
              <li>
                <a href="/services/hot-water-systems/" onClick={handleLinkClick}>
                  <span className='menu-icon'>
                    <picture>
                      <source type='image/webp' srcset={HotWaterSystemsIconWebp} />
                      <source type='image/png' srcset={HotWaterSystemsIconPng} />
                      <img src={HotWaterSystemsIconWebp} alt="Hot Water Systems icon" />
                    </picture>
                  </span>
                  <span className='menu-text'>Hot Water Systems</span>
                </a>
              </li>
              <li>
                <a href="/services/plumbing-maintenance" onClick={handleLinkClick}>
                  <span className='menu-icon'>
                    <picture>
                      <source type='image/webp' srcset={PlumbingMaintenanceIconWebp} />
                      <source type='image/png' srcset={PlumbingMaintenanceIconPng} />
                      <img src={PlumbingMaintenanceIconWebp} alt="Plumbing Maintenance icon" />
                    </picture>
                  </span>
                  <span className='menu-text'>Plumbing Maintenance</span>
                </a>
              </li>
              {/* <li>
                <a href="/services/water-filter-installation" onClick={handleLinkClick}>
                  <span className='menu-icon'>
                    <picture>
                      <source type='image/webp' srcset={WaterFilterIconWebp} />
                      <source type='image/png' srcset={WaterFilterIconPng} />
                      <img src={WaterFilterIconWebp} alt="Water Filter icon" />
                    </picture>
                  </span>
                  <span className='menu-text'>Water Filter Installation</span>
                </a>
              </li> */}
              <li>
                <a href="/#our-services" onClick={handleLinkClick}>
                  <span className='menu-icon'>
                    <picture>
                      <source type='image/webp' srcset={WaterLeaksIconWebp} />
                      <source type='image/png' srcset={WaterLeaksIconPng} />
                      <img src={WaterLeaksIconWebp} alt="Water Leaks icon" />
                    </picture>
                  </span>
                  <span className='menu-text'>Water Leaks</span>
                </a>
              </li>
              <li>
                <a href="/#our-services" onClick={handleLinkClick}>
                  <span className='menu-icon'>
                    <picture>
                      <source type='image/webp' srcset={BathroomIconWebp} />
                      <source type='image/png' srcset={BathroomIconPng} />
                      <img src={BathroomIconWebp} alt="Bathroom & Kitchen icon" />
                    </picture>
                    </span>
                  <span className='menu-text'>Bathroom & Kitchen</span>
                </a>
              </li>
              <li>
                <a href="#about-us" className='call-btn-adjacent' onClick={handleLinkClick}>
                  <span className='menu-icon'>
                    <picture>
                      <source type='image/webp' srcset={AboutIconWebp} />
                      <source type='image/png' srcset={AboutIconPng} />
                      <img src={AboutIconWebp} alt="About Us icon" />
                    </picture>
                  </span>
                  <span className='menu-text'>About Us</span>
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  )
}

export default Nav