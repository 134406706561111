import React from 'react';
import ContactForm from './Contact';

const Section7 = () => {
    return (
      
      <section id="section-contact" className="section-contact">
        <div className="container boxed">
        <div className='row'>
            <div className='col col-100'>
              <h2 className='title'>Contact Us</h2>
              <p className='subtitle'>Get in touch with us to see how we can help you with your plumbing needs.</p>
        
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
  
    )
  }
  
  export default Section7