import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './css/globals.css';
import './css/contact.css';
import './css/header.css';
import './css/footer.css';
import './css/page.css';
import Header from './sections/Header';
import Footer from './sections/Footer';
import Banner from './sections/Banner';
import Cta from './sections/Cta';
import Section3 from './sections/Section3';
import Section4 from './sections/Section4';
import Section5 from './sections/Section5';
import Section6 from './sections/Section6';
import Section7 from './sections/Section7';
import Section8 from './sections/Section8';
import Section9 from './sections/Section9';
import Section10 from './sections/Section10';
import Section11 from './sections/Section11';
import Accordion from './sections/Accordion';

const WaterFilterInstallationPage = () => {

    const schema = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        'name': 'ImperialPDG', 
        'description': 'Dependable plumbing professionals trusted by Gold Coast residents. Rely on us to resolve all your domestic and commercial plumbing issues promptly and effectively',
        'address': {
          '@type': 'PostalAddress',
          'addressLocality': 'Gold Coast',
          'addressRegion': 'QLD',
          'postalCode': '9726', 
          'streetAddress': 'PO Box 4916' 
        },
        'location': {
            '@type': 'Place',
            'name': 'ImperialPDG', 
            'geo': {
              '@type': 'GeoCoordinates',
              'latitude': -28.01294080513038, 
              'longitude': 153.40505834857066
            }
          },
      };

  const [pageTitle, setPageTitle] = useState('Dynamic Page Title');
  const [metaDescription, setMetaDescription] = useState('This is a dynamic description for the page.');
  const [metaKeywords, setMetaKeywords] = useState('react, dynamic, meta tags');

  useEffect(() => {
    console.log('Meta Description:', metaDescription);
    console.log('Meta Keywords:', metaKeywords);
    setPageTitle(`Bathroom and Kitchen Plumbing Services | Imperial PDG Gold Coast`);
    setMetaDescription(`Discover top-tier bathroom and kitchen plumbing services with Imperial PDG, your local Bundall-based plumber on the Gold Coast`);
    setMetaKeywords(`bathroom and kitchen plumbing gold coast`);
 }, []);

 
 useEffect(() => {
    // Update meta tags whenever metaDescription or metaKeywords change
    const helmet = Helmet.peek();
    helmet.metaTags = [
      { name: 'description', content: metaDescription },
      { name: 'keywords', content: metaKeywords }
    ];
  }, [metaDescription, metaKeywords]);
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Bathroom and Kitchen Plumbing Services | Imperial PDG Gold Coast",
              "description": "Discover top-tier bathroom and kitchen plumbing services with Imperial PDG, your local Bundall-based plumber on the Gold Coast",
              "url": "https://www.imperialpdg.com.au/",
              "image": {
                "@type": "ImageObject",
                "url": "https://www.imperialpdg.com.au/img/hot-water-system-installation-gold-coast-2.webp",
                "width": 1200,
                "height": 630
              },
              "breadcrumb": {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                      "@id": "https://www.imperialpdg.com.au/",
                      "name": "Home"
                    }
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                      "@id": "https://www.imperialpdg.com.au/services/bathroom-kitchen-plumbing/",
                      "name": "Bathroom and Kitchen Plumbing"
                    }
                  }
                ]
              }
            }
          `}
        </script>
      </Helmet>
      <div className='waterLeaks'>
        <Header />
        <Banner 
          backgroundImg={"/img/hot-water-system-installation-gold-coast-top-banner.webp"}
          backgroundAltText={"Plumber walking with a tool bag outside"}
          title={"<b>Bathroom and Kitchen Plumbing</b>"}
          subtitle={"Expert Solutions for Your Home's Essential Spaces"}
        />
        <Cta />
        <Section9
          imgWebp={"/img/Water-Filter-Installation-Gold-Coast-second-image.webp"}
          imgJpg={"/img/Water-Filter-Installation-Gold-Coast-second-image.jpg"}
          imgAlt={"Plumber with a tool bag walking beside a hot water system"}
          title={"Your Premier Gold Coast Plumber in Bundall"}
          subtitle={"Welcome to Imperial PDG"}
          desc={"<p class='desc'>Do you need top-notch bathroom and kitchen plumbing services on the Gold Coast? Look no further than Imperial PDG, the local plumbing authority based right in Bundall. Our expert team of plumbers brings unparalleled craftsmanship, dedication, and precision to every job, ensuring your plumbing needs are met with the highest standards of quality and efficiency.</p>"}
        />
        <Section10
          imgWebp={"/img/hot-water-system-installation-gold-coast-3.webp"}
          imgJpg={"/img/hot-water-system-installation-gold-coast-3.jpg"}
          imgAlt={"Plumber with a tool bag installing a hot water system"}
          title={"Why Choose Imperial PDG?"}
          subtitle={"Bathroom & Kitchen Solutions"}
          desc={"<h6>Local Expertise</h6><p class='desc'>As a local Gold Coast plumber, we know Bundall residents' specific plumbing needs and challenges. This allows us to provide tailored solutions that work best for you.</p><h6>Comprehensive Services</h6><p class='desc'>From leaky taps in your kitchen to complete bathroom renovations, our services cover all aspects of plumbing. We ensure your water flows where it should, smoothly and efficiently.</p><h6>Quality Assurance</h6><p class='desc'>Our commitment to quality means using only the best materials and the latest techniques. We stand by our work with a satisfaction guarantee, giving you peace of mind.</p></p><h6>Customer-Centric Approach</h6><p class='desc'>At Imperial PDG, we prioritize your convenience and satisfaction. Our friendly team is always ready to listen and address your plumbing concerns promptly and effectively.</p><p class='desc'>Don't let plumbing issues dampen your day. Whether it's a minor repair or a major renovation, trust Imperial PDG to deliver exceptional results that stand the test of time. Contact us today to experience the difference of working with the finest plumbers on the Gold Coast.</p>"} 
        />
        <Section9
          imgWebp={"/img/Water-Filter-Installation-Gold-Coast-second-image.webp"}
          imgJpg={"/img/Water-Filter-Installation-Gold-Coast-second-image.jpg"}
          imgAlt={"Plumber with a tool bag walking beside a hot water system"}
          title={"Elevating Bathroom and Kitchen Plumbing on the Gold Coast"}
          subtitle={"Imperial PDG"}
          desc={"<p class='desc'>Embark on a seamless plumbing journey with Imperial PDG, the trusted local plumber of choice for Bundall and the wider Gold Coast area. Specialising in both bathroom and kitchen plumbing, our team combines technical expertise with a deep understanding of our clients' needs, ensuring every project is a resounding success.</p>"}
        />

        <Section10
          imgWebp={"/img/hot-water-system-installation-gold-coast-3.webp"}
          imgJpg={"/img/hot-water-system-installation-gold-coast-3.jpg"}
          imgAlt={"Plumber with a tool bag installing a hot water system"}
          title={"Our Promise to You"}
          subtitle={"Commitment"}
          desc={"<h6>Local Expertise</h6><p class='desc'>Being based in Bundall gives us the edge in providing prompt and reliable plumbing services tailored to the Gold Coast lifestyle.</p><h6>Versatile Solutions</h6><p class='desc'>Our extensive range of services, from quick fixes to full-scale renovations, means we're equipped to handle any plumbing challenge in your bathroom or kitchen.</p><h6>Superior Quality</h6><p class='desc'>With Imperial PDG, quality is never compromised. We use the finest materials and state-of-the-art methods to ensure durable and effective plumbing solutions.</p><p class='desc'>Only pure, filtered water from your own water supply is left for your health, safety and peace of mind.</p><h6>Unmatched Customer Service</h6><p class='desc'>We believe in building lasting relationships with our clients. Our approachable and professional team is dedicated to solving your plumbing issues with minimal fuss and maximum efficiency.</p><p class='desc'>Make the right choice for your bathroom and kitchen plumbing needs. Choose Imperial PDG for a hassle-free service that guarantees satisfaction and longevity. Contact us now for a consultation, and let us make your plumbing worries a thing of the past.</p>"}   
        />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />  
        <Section8 />
        <Section7 />
        <Footer />
      </div>
    </>

  )
}

export default WaterFilterInstallationPage


