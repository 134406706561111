import React from 'react'
import EmergencyIconWebp from '../img/emergency.webp';
import EmergencyIconPng from '../img/emergency.png';
import MaintenanceIconWebp from '../img/emergency.webp';
import MaintenanceIconPng from '../img/emergency.png';

const Cta = () => {
  return (
    <section id="cta">
      <div className='container boxed'>
        <div className='row'>
          <div className='col col-100'>
            <div className='cta-holder'>
              <a href="tel:1300631226" className='cta'>
                <span className='cta-text-sub'>CALL NOW</span>
                <span className='cta-text'>1300 631 226</span>
              </a>
              <div className='cta-shape'></div>
            </div>
            <div className='cta-options'>
              <div className='cta-option'>
                <div className='option-img'>
                  <picture>
                    <source type='image/webp' srcset={EmergencyIconWebp} />
                    <source type='image/png' srcset={EmergencyIconPng} />
                    <img src={EmergencyIconWebp} alt="Imperial PDG call icon" />
                  </picture>
                </div>
                <div className='option-details'>
                  <h2 className='option-title'>PLUMBING SOLUTIONS</h2>
                  <p className='option-desc'>We’re open Monday - Friday 7am to 5pm for all plumbing issues. <a href="tel:1300631226">Call us Now.</a></p>
                </div>
              </div>
              <div className='cta-option'>
                <div className='option-img'>
                  <picture>
                    <source type='image/webp' srcset={MaintenanceIconWebp} />
                    <source type='image/png' srcset={MaintenanceIconPng} />
                    <img src={MaintenanceIconWebp} alt="Imperial PDG call icon" />
                  </picture>
                </div>
                <div className='option-details'>
                  <h2 className='option-title'>RESIDENTIAL PLUMBING</h2>
                  <p className='option-desc'>We cater to all your domestic plumbing needs. <a href="tel:+1300631226">Call us Now.</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cta