import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Scrollbar} from 'swiper/modules';
import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/scrollbar';
SwiperCore.use([Navigation]);

const Testimonials = () => {

  return (

    <div className='swiper-holder'>
      <Swiper
        modules={[Scrollbar, Autoplay, Navigation]}
        scrollbar={{
          hide: false,
        }}
        autoplay={{ 
          delay: 5000, 
        }}
        navigation
        className="testimonials"
      >
        <SwiperSlide>
          <p className='testimonial-desc'>"I recently had Mitch from Imperial Plumbing install a water filter in my new home. I was genuinely impressed by the quick turnaround, excellent service, and transparent upfront pricing. Mitch was professional and ensured everything was in perfect order before leaving. Highly recommend!"</p>
          <h3 className='testimonial-name'>Lee B</h3>
        </SwiperSlide>
        <SwiperSlide>
          <p className='testimonial-desc'>"Mitch turned up when he said he was going to and did a great job at repairing a damaged stormwater pipe in the backyard. When he was done, the repair was very neat and he was very easy to deal with."</p>
          <h3 className='testimonial-name'>Brad Freeman</h3>
        </SwiperSlide>
        <SwiperSlide>
          <p className='testimonial-desc'>"We urgently needed a water filter moved from our previous home to our new one, and Mitch from Imperial Plumbing was our lifesaver. With just a day's notice, he was at our doorstep first thing in the morning, ensuring a seamless transfer.<br></br><br></br>Mitch's accommodating nature and hassle-free approach were genuinely commendable.<br></br><br></br>We've already decided to rely on him for all plumbing aspects of our new home's renovations. Can't recommend Mitch and Imperial Plumbing enough!"</p>
          <h3 className='testimonial-name'>Georgina Davies</h3>
        </SwiperSlide>
      </Swiper>
    </div>

  )
}

export default Testimonials