import React from 'react';

const Banner = ({ backgroundImg, backgroundAltText, title, subtitle, desc }) => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImg})`,
  };

  return (
    <section id="banner" className='section'>
      <div
        className='background-holder'
        role="img"
        aria-label={backgroundAltText}
        style={backgroundStyle}
      >
        <span class="sr-only">{backgroundAltText}</span>
      </div>
      <div className='container boxed'>
        <div className='row'>
          <div className='col col-60 left'>
            <div className='content-holder'>
              <h1 className='title' dangerouslySetInnerHTML={{ __html: title }} />
              {subtitle && <h3 className='subtitle'>{subtitle}</h3>}
              {desc && <p className='desc'>{desc}</p>}
            </div>
            <div className='btn-holder'>
              <a href='tel:1300631226' className='btn btn-primary'>Call now</a>
              <a href='#section-contact' className='btn btn-secondary'>Request a quote</a>
            </div>
          </div>
          <div className='col col-40 right'></div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
