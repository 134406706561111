import React from 'react';
import Locations from './Locations';

const Section8 = () => {
  return (

    <section className="section-locations">
      <div className="container boxed">
        <div className="row">
          <div className="col col-100">	
            <h2 className="title">We service from Tweed to Gold Coast.<br />Including these suburbs and more:</h2>
            <Locations />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section8