import React from 'react';
import { Link } from 'react-router-dom';

const ThankYouPage = () => {

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      background: '#009abf',
      color: '#fff'
    }}>
      <div className="thank-you">
        <h1>Thank You for Contacting Us!</h1>
        <p>We appreciate your interest and will get back to you soon.</p>
        <Link
          to="/"
          style={{
            display: 'inline-block',
            marginTop: '20px',
            padding: '10px 20px',
            background: '#fff',
            color: '#009abf',
            textDecoration: 'none',
            borderRadius: '5px',
            transition: 'background 0.3s ease',
          }}
          // Hover effect styles
          onMouseEnter={(e) => e.target.style.background = '#e1e1e1'}
          onMouseLeave={(e) => e.target.style.background = '#fff'}
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default ThankYouPage;
