import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import MitchImageWebp from '../img/plumber-making-phone-gesture.webp'
import MitchImagePng from '../img/plumber-making-phone-gesture.webp'

const Section4 = () => {
  return (
    <section className="section-4">
      <div className='container wide'>
        <div className='row'>
          <div className='col col-30'>
            <div className='content-holder left'>
              <p className='subtitle'>Connect  With Us</p>
              <h2 className='title'>LOOKING FOR A LOCAL PLUMBER?</h2>
            </div>
          </div>
          <div className='col col-40'>
            <div className='img-holder desktop'>
              <picture>
                <source type='image/webp' srcset={MitchImageWebp} />
                <source type='image/png' srcset={MitchImagePng} />
                <img src={MitchImageWebp} alt='Mitch from imperial plumbing is doing the call signal for a local plumber' draggable="false" />
              </picture>
            </div>
          </div>
          <div className='col col-30'>
            <div className='content-holder right'>
              <p className='desc'>Got a question or a plumbing crisis? call our plumbing squad on the number below, they're ready to help.</p>
              <div className='btn-holder'>                  
                <a href="tel:1300631226" className='call-btn'>
                  <span className='menu-icon step'>
                    <span className='step-inner'>
                      <FontAwesomeIcon icon={faPhone} size="lg" color="white" />
                    </span>
                  </span>
                  <span className='menu-text-sub'>CALL US NOW</span>
                  <span className='menu-text'>1300 631 226</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section4