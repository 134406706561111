import React, { useState } from 'react';

const Section11 = ({ title, subtitle, desc }) => {

  return (
    <section className="section-11">

      <div className='container full'>

        <div className='row'>
          <div className='col col-100'>
            <div className='content-holder'>
              <p className='subtitle'>{subtitle}</p>
              <h3 className='title'>{title}</h3>
              <div className='desc' dangerouslySetInnerHTML={{ __html: desc }} />
            </div>
          </div>
        </div>
        

      </div>

    </section>

  );
};

export default Section11;