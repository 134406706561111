import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './css/globals.css';
import './css/contact.css';
import './css/header.css';
import './css/footer.css';
import './css/page.css';
import Header from './sections/Header';
import Footer from './sections/Footer';
import Banner from './sections/Banner';
import Cta from './sections/Cta';
import Section3 from './sections/Section3';
import Section4 from './sections/Section4';
import Section5 from './sections/Section5';
import Section6 from './sections/Section6';
import Section7 from './sections/Section7';
import Section8 from './sections/Section8';
import Section9 from './sections/Section9';
import Section10 from './sections/Section10';
import Section11 from './sections/Section11';
import Accordion from './sections/Accordion';

const WaterFilterInstallationPage = () => {

    const schema = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        'name': 'ImperialPDG', 
        'description': 'Dependable plumbing professionals trusted by Gold Coast residents. Rely on us to resolve all your domestic and commercial plumbing issues promptly and effectively',
        'address': {
          '@type': 'PostalAddress',
          'addressLocality': 'Gold Coast',
          'addressRegion': 'QLD',
          'postalCode': '9726', 
          'streetAddress': 'PO Box 4916' 
        },
        'location': {
            '@type': 'Place',
            'name': 'ImperialPDG', 
            'geo': {
              '@type': 'GeoCoordinates',
              'latitude': -28.01294080513038, 
              'longitude': 153.40505834857066
            }
          },
      };

  const [pageTitle, setPageTitle] = useState('Dynamic Page Title');
  const [metaDescription, setMetaDescription] = useState('This is a dynamic description for the page.');
  const [metaKeywords, setMetaKeywords] = useState('react, dynamic, meta tags');

  useEffect(() => {
    console.log('Meta Description:', metaDescription);
    console.log('Meta Keywords:', metaKeywords);
    setPageTitle(`Expert Water Leak Detection & Repair Services | Imperial PDG Plumber Gold Coast`);
    setMetaDescription(`Discover top-notch water leak detection and repair services with Imperial PDG Plumber on the Gold Coast.`);
    setMetaKeywords(`water leak detection gold coast`);
 }, []);

 
 useEffect(() => {
    // Update meta tags whenever metaDescription or metaKeywords change
    const helmet = Helmet.peek();
    helmet.metaTags = [
      { name: 'description', content: metaDescription },
      { name: 'keywords', content: metaKeywords }
    ];
  }, [metaDescription, metaKeywords]);
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Expert Water Leak Detection & Repair Services | Imperial PDG Plumber Gold Coast",
              "description": "Discover top-notch water leak detection and repair services with Imperial PDG Plumber on the Gold Coast.",
              "url": "https://www.imperialpdg.com.au/",
              "image": {
                "@type": "ImageObject",
                "url": "https://www.imperialpdg.com.au/img/hot-water-system-installation-gold-coast-2.webp",
                "width": 1200,
                "height": 630
              },
              "breadcrumb": {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                      "@id": "https://www.imperialpdg.com.au/",
                      "name": "Home"
                    }
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                      "@id": "https://www.imperialpdg.com.au/services/water-leaks/",
                      "name": "Water Leaks Detection"
                    }
                  }
                ]
              }
            }
          `}
        </script>
      </Helmet>
      <div className='waterLeaks'>
        <Header />
        <Banner 
          backgroundImg={"/img/hot-water-system-installation-gold-coast-top-banner.webp"}
          backgroundAltText={"Plumber walking with a tool bag outside"}
          title={"<b>Water Leaks</b> <br>Gold Coast"}
          subtitle={"Swift and Reliable Leak Detection and Repair Services"}
        />
        <Cta />
        <Section9
          imgWebp={"/img/Water-Filter-Installation-Gold-Coast-second-image.webp"}
          imgJpg={"/img/Water-Filter-Installation-Gold-Coast-second-image.jpg"}
          imgAlt={"Plumber with a tool bag walking beside a hot water system"}
          title={"Expert Leak Detection and Repair Services"}
          subtitle={"Welcome to Imperial PDG"}
          desc={"<p class='desc'>Discover unparalleled expertise with Imperial PDG Plumber, your premier solution for water leaks on the Gold Coast. Our seasoned professionals utilise the latest technology and techniques to swiftly identify and rectify any leaks, ensuring your home or business is protected against water damage. With a commitment to excellence and a keen eye for detail, we guarantee a hassle-free service that not only fixes your current issues but also safeguards your property for the future. Choose Imperial PDG Plumber for a watertight service that stands the test of time.</p>"}
        />
        <Section10
          imgWebp={"/img/bundalls-gold-coast-plumbing-expert-3.webp"}
          imgJpg={"/img/bundalls-gold-coast-plumbing-expert-3.jpg"}
          imgAlt={"Plumber with a tool bag installing a hot water system"}
          title={"Why Choose Imperial PDG?"}
          subtitle={"Water Leakage Solutions"}
          desc={"<h6>Swift Detection</h6><p class='desc'>Leveraging cutting-edge technology to quickly locate leaks, saving you time and money.</p><h6>Comprehensive Solutions</h6><p class='desc'>From minor repairs to major overhauls, our services cover every aspect of leak prevention and repair.</p><h6>Exceptional Service</h6><p class='desc'>Our team of friendly, skilled professionals is dedicated to providing outstanding customer service and impeccable workmanship.</p>"} 
        />
        <Section9
          imgWebp={"/img/Water-Filter-Installation-Gold-Coast-second-image.webp"}
          imgJpg={"/img/Water-Filter-Installation-Gold-Coast-second-image.jpg"}
          imgAlt={"Plumber with a tool bag walking beside a hot water system"}
          title={"The Gold Standard in Water Leak Repairs"}
          subtitle={"The Difference"}
          desc={"<p class='desc'>At Imperial PDG Plumber, we set the gold standard for water leak detection and repair on the Gold Coast. Our expert team combines traditional craftsmanship with modern technology to deliver services that not only resolve your immediate concerns but also enhance the longevity and efficiency of your plumbing system. Embrace peace of mind with our comprehensive approach to leak detection, where every repair is executed with precision and care, ensuring lasting results.</p>"}
        />

        <Section10
          imgWebp={"/img/bundalls-gold-coast-plumbing-expert-3.webp"}
          imgJpg={"/img/bundalls-gold-coast-plumbing-expert-3.jpg"}
          imgAlt={"Plumber with a tool bag installing a hot water system"}
          title={"What Sets Us Apart?"}
          subtitle={"Commitment"}
          desc={"<h6>Innovative Techniques</h6><p class='desc'>Employing the latest in leak detection technology for accuracy and efficiency.</p><h6>Tailored Solutions</h6><p class='desc'>Understanding that each leak is unique, we provide customised repair strategies to best suit your needs.</p><h6>Customer Satisfaction</h6><p class='desc'>At the heart of our service is a commitment to ensuring your complete satisfaction, reflected in our meticulous attention to detail and superior service.</p><p class='desc'>Whether you're facing an urgent leak issue or seeking to prevent future problems, Imperial PDG Plumber is your trusted partner on the Gold Coast. With our expert team, state-of-the-art technology, and commitment to quality, we're here to provide a seamless and effective solution to water leaks. Choose excellence. Choose Imperial PDG Plumber.</p>"}  
        />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />  
        <Section8 />
        <Section7 />
        <Footer />
      </div>
    </>

  )
}

export default WaterFilterInstallationPage

