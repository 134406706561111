import React from 'react';
import { Helmet } from 'react-helmet';
import './css/globals.css';
import './css/contact.css';
import './css/header.css';
import './css/footer.css';
import './css/page.css';
import Header from './sections/Header';
import Footer from './sections/Footer';
import Banner from './sections/Banner';
import Cta from './sections/Cta';
import Section1 from './sections/Section1';
import Section2 from './sections/Section2';
import Section3 from './sections/Section3';
import Section4 from './sections/Section4';
import Section5 from './sections/Section5';
import Section6 from './sections/Section6';
import Section7 from './sections/Section7';
import Section8 from './sections/Section8';

const Homepage = () => {

const schema = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    'name': 'ImperialPDG', // Your site name
    'description': 'Dependable plumbing professionals trusted by Gold Coast residents. Rely on us to resolve all your domestic and commercial plumbing issues promptly and effectively',
    'address': {
        '@type': 'PostalAddress',
        'addressLocality': 'Gold Coast',
        'addressRegion': 'QLD',
        'postalCode': '9726', // Add your postal code here
        'streetAddress': 'PO Box 4916' // Add your street address here
    },
    'location': {
      '@type': 'Place',
      'name': 'ImperialPDG', // Name of your location
      'geo': {
        '@type': 'GeoCoordinates',
        'latitude': -28.01294080513038, // Your latitude
        'longitude': 153.40505834857066 // Your longitude
      }
    },
    };

    return (
    <>
        <Helmet>
        <script type="application/ld+json">
            {JSON.stringify(schema)}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Hot Water Systems Gold Coast",
              "description": "Expert installation & servicing for hot water systems on the Gold Coast. Fast & reliable service.",
              "url": "https://www.imperialpdg.com.au/",
              "image": {
                "@type": "ImageObject",
                "url": "https://www.imperialpdg.com.au/img/hot-water-system-installation-gold-coast-2.webp",
                "width": 1200,
                "height": 630
              },
              "breadcrumb": {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                      "@id": "https://www.imperialpdg.com.au/",
                      "name": "Home"
                    }
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                      "@id": "https://www.imperialpdg.com.au/services/hot-water-systems/",
                      "name": "Hot Water Systems"
                    }
                  }
                ]
              }
            }
          `}
        </script>
        </Helmet>

    <div className='homepage'>

      <Header />
      <Banner 
        backgroundImg={"/img/imperial-pdg-banner-image.webp"}
        backgroundAltText={"Plumber walking into high-end Gold Kitchen with a tool bag"}
        title={"<b>Gold Coast's</b><br>most trusted local plumber"}
        desc={"Dependable plumbing professionals trusted by Gold Coast residents. Rely on us to resolve all your domestic and commercial plumbing issues promptly and effectively"}
      />
      <Cta />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />  
      <Section8 />
      <Section7 />
      <Footer />

    </div>
    </>

  )
}

export default Homepage


