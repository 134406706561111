import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './css/globals.css';
import './css/contact.css';
import './css/header.css';
import './css/footer.css';
import './css/page.css';
import Header from './sections/Header';
import Footer from './sections/Footer';
import Banner from './sections/Banner';
import Cta from './sections/Cta';
import Section3 from './sections/Section3';
import Section4 from './sections/Section4';
import Section5 from './sections/Section5';
import Section6 from './sections/Section6';
import Section7 from './sections/Section7';
import Section8 from './sections/Section8';
import Section9 from './sections/Section9';
import Section10 from './sections/Section10';
import Section11 from './sections/Section11';
import Accordion from './sections/Accordion';

const BundallPlumbingExpert = () => {
  
    const schema = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        'name': 'ImperialPDG', // Your site name
        'description': 'Dependable plumbing professionals trusted by Gold Coast residents. Rely on us to resolve all your domestic and commercial plumbing issues promptly and effectively',
        'address': {
          '@type': 'PostalAddress',
          'addressLocality': 'Gold Coast',
          'addressRegion': 'QLD',
          'postalCode': '9726', // Add your postal code here
          'streetAddress': 'PO Box 4916' // Add your street address here
        },
        'location': {
            '@type': 'Place',
            'name': 'ImperialPDG', // Name of your location
            'geo': {
              '@type': 'GeoCoordinates',
              'latitude': -28.01294080513038, // Your latitude
              'longitude': 153.40505834857066 // Your longitude
            }
          },
      };

  const [pageTitle, setPageTitle] = useState('Dynamic Page Title');
  const [metaDescription, setMetaDescription] = useState('This is a dynamic description for the page.');
  const [metaKeywords, setMetaKeywords] = useState('react, dynamic, meta tags');

  useEffect(() => {
    console.log('Meta Description:', metaDescription);
    console.log('Meta Keywords:', metaKeywords);
    setPageTitle(`Imperial PDG: Expert Plumbers in Bundall - Reliable & Efficient Plumbing Services`);
    setMetaDescription('Discover top-notch plumbing solutions with Imperial PDG, your local Bundall plumbing experts. From emergency repairs to installations, our certified plumbers ensure fast, reliable service for all your needs. Contact us today for a hassle-free experience!');
    setMetaKeywords('bundall gold coast leading plumbing experts');
 }, []);

 
 useEffect(() => {
    // Update meta tags whenever metaDescription or metaKeywords change
    const helmet = Helmet.peek();
    helmet.metaTags = [
      { name: 'description', content: metaDescription },
      { name: 'keywords', content: metaKeywords }
    ];
  }, [metaDescription, metaKeywords]);

  const accordionData = [
    {
      title: 'What plumbing services do you offer in Bundall?',
      content: `Our Bundall-based plumbing services cover a wide range of needs, including leak detection and repair, pipe cleaning and descaling, hot water system maintenance, drain and sewer line servicing, and comprehensive plumbing maintenance. Our team is equipped to handle both routine and emergency plumbing issues.`
    },
    {
      title: 'Are your plumbers certified for work in Bundall?',
      content: `Yes, all our plumbers are certified and have extensive experience in the plumbing industry. We ensure that our team in Bundall is fully equipped with the necessary qualifications to provide top-quality plumbing services.`
    },
    {
      title: 'How quickly can a plumber from Imperial PDG respond to an emergency in Bundall?',
      content: `Our goal is to provide prompt and efficient service to our Bundall customers. In case of an emergency, we strive to respond as quickly as possible, with the aim of addressing your plumbing issues in a timely manner.`
    },
    {
      title: 'Can you help with hot water system issues in Bundall?',
      content: `Absolutely. Our plumbers in Bundall are experts in hot water system maintenance and can assist with any issues you may be experiencing, from system repairs to full replacements and regular servicing to ensure your system runs smoothly.`
    },
    {
      title: 'Do you offer regular plumbing maintenance contracts in Bundall?',
      content: `Yes, we offer plumbing maintenance contracts for both residential and commercial properties in Bundall. These contracts are designed to keep your plumbing systems in optimal condition, preventing potential issues before they arise.`
    },
    {
      title: 'What makes Imperial PDG stand out from other plumbers in Bundall?',
      content: `Imperial PDG stands out due to our commitment to providing high-quality plumbing services, our team of experienced and certified plumbers, and our dedication to customer satisfaction. We pride ourselves on our reliable service and the trust we've built within the Bundall community.`
    },
    {
      title: 'How can I book a plumbing service in Bundall with Imperial PDG?',
      content: `Booking a plumbing service in Bundall with Imperial PDG is easy. You can contact us via our website, phone, or email to schedule an appointment. Our team is ready to assist you with your plumbing needs at your convenience.`
    },
  ];
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Hot Water Systems Gold Coast",
              "description": "Expert installation & servicing for hot water systems on the Gold Coast. Fast & reliable service.",
              "url": "https://www.imperialpdg.com.au/",
              "image": {
                "@type": "ImageObject",
                "url": "https://www.imperialpdg.com.au/img/hot-water-system-installation-gold-coast-2.webp",
                "width": 1200,
                "height": 630
              },
              "breadcrumb": {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                      "@id": "https://www.imperialpdg.com.au/",
                      "name": "Home"
                    }
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                      "@id": "https://www.imperialpdg.com.au/services/hot-water-systems/",
                      "name": "Hot Water Systems"
                    }
                  }
                ]
              }
            }
          `}
        </script>
      </Helmet>
      <div className='hotwatersystems'>
        <Header />
        <Banner 
          backgroundImg={"/img/hot-water-system-installation-gold-coast-top-banner.webp"}
          backgroundAltText={"Plumber walking with a tool bag outside"}
          title={"<b>Bundall's Leading Plumbing Experts</b>"}
        />
        <Cta />
        <Section9
          imgWebp={"/img/Water-Filter-Installation-Gold-Coast-second-image.webp"}
          imgJpg={"/img/Water-Filter-Installation-Gold-Coast-second-image.jpg"}
          imgAlt={"Plumber with a tool bag walking beside a hot water system"}
          title={"Why Choose Us"}
          subtitle={"Plumbing Experts"}
          desc={"<p class='desc'>In need of prompt and reliable plumbing solutions in Bundall? Look no further, as Imperial PDG is at your service to address all your plumbing concerns, catering to both residential and commercial clients.</p><p class='desc'></Section9>With over two decades of dedication, our skilled plumbers at Imperial PDG are now serving the needs of the Gold Coast and South-East Queensland community. We are committed to delivering a professional and cost-efficient service, a promise that has been our cornerstone, winning over thousands of satisfied customers.</p><p class='desc'>Choose Imperial PDG for your Bundall plumbing needs and experience the pinnacle of quality service at competitive prices.</p>"}
        />
        <Section10
          imgWebp={"/img/bundalls-gold-coast-plumbing-expert-2.webp"}
          imgJpg={"/img/bundalls-gold-coast-plumbing-expert-2.jpg"}
          imgAlt={"Plumber with a tool bag installing a hot water system"}
          title={"Hot Water Systems in Bundall: Ensuring Your Comfort"}
          subtitle={"Hot Water System Solutions"}
          desc={"<p class='desc'>Ensuring access to a reliable hot water system is essential for the comfort of daily living, from bathing to cleaning. The right installation is crucial to avoid common issues such as insufficient hot water supply or low pressure, with natural wear also being a significant factor over time. Our experienced Bundall plumbers excel in repairing and replacing a variety of hot water systems, including electric, gas, storage tanks, solar, and instantaneous models, ensuring compliance with the high standards of the Queensland Building and Construction Commission. We provide a full suite of services, employing licensed professionals for all plumbing and gas fitting tasks to ensure your system runs smoothly. Our comprehensive approach includes updating pipework, installing compliant fittings, and managing all necessary regulatory submissions, backed by a lifetime warranty on workmanship for peace of mind. Whether you're considering a new system or updating an existing one, our Bundall hot water specialists are ready to offer tailored solutions that meet your specific needs.</p>"}
        />

        <Section9
          imgWebp={"/img/bundalls-gold-coast-plumbing-expert-3.webp"}
          imgJpg={"/img/bundalls-gold-coast-plumbing-expert-3.jpg"}
          imgAlt={"Plumber with a tool bag installing a hot water system"}
          title={"Your Go-To Plumbing Maintenance Experts in Bundall, Gold Coast!"}
          subtitle={"Water Filter Specialists Gold Coast"}
          desc={"<p class='desc'>In search of professional plumbing maintenance services within Bundall or the broader Gold Coast region? Imperial PDG stands out as your reliable partner, committed to offering exceptional plumbing maintenance solutions specifically designed for the residents of Bundall.</p><p class='desc'>Our skilled team, boasting certifications and extensive experience, is fully prepared to address all aspects of plumbing maintenance, regardless of scale. Whether it's standard checks and cleans or urgent fixes, we ensure comprehensive coverage for your plumbing system.</p>"}
        />

        <Section10
          imgWebp={"/img/bundalls-gold-coast-plumbing-expert-4.webp"}
          imgJpg={"/img/bundalls-gold-coast-plumbing-expert-4.jpg"}
          imgAlt={"Plumber with a tool bag installing a hot water system"}
          title={"Our Bundall Plumbing Maintenance Services Encompass:"}
          subtitle={"Services We Offer"}
          desc={"<p class='desc'>Thorough Inspections and Evaluations</p><p class='desc'>TPrecision Leak Detection and Swift Repairs</p><p class='desc'>TPipe Cleansing and Descaling Operations</p><p class='desc'>THot Water System Upkeep</p><p class='desc'>TDrainage and Sewer Line Servicing</p><p class='desc'>TTailored to enhance your home, we aim to be your first choice for plumbing maintenance needs in Bundall, ensuring your plumbing system operates flawlessly.</p>"}
        />

        <Section9
          imgWebp={"/img/bundalls-gold-coast-plumbing-expert-5.webp"}
          imgJpg={"/img/bundalls-gold-coast-plumbing-expert-5.jpg"}
          imgAlt={"Plumber with a tool bag installing a hot water system"}
          title={"Your Leading Water Filter Experts in Bundall, Gold Coast!"}
          subtitle={"Unparalleled Advice and Solutions"}
          desc={"<p class='desc'>Bundall's choice for top-tier water filtration solutions on the Gold Coast. With a longstanding commitment to improving your water quality, we've become synonymous with excellence in Australia's water filtration market, driven by our core family values and dedication to high-quality products and reliable service.</p><p class='desc'>Our selection features a range of water filtration options, from the practicality of under-sink systems to the extensive benefits of Whole House Systems. Our experienced team is on hand to assist you in selecting the perfect water filtration solution, ensuring you have access to clean, healthy water.</p><p class='desc'>By focusing on Bundall and the wider Gold Coast area, we aim to solidify our position as your preferred water filtration specialists, offering unparalleled advice and solutions tailored to meet your water quality needs.</p>"}
        />

        <Section11
          title={"FAQs: Plumber Bundall"}
          subtitle={"Bundall's Leading Plumbing Experts"}
         />
        <div className="accordion boxed">
          {accordionData.map(({ title, content }) => (
            <Accordion title={title} content={content} />
          ))}
        </div>
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />  
        <Section8 />
        <Section7 />
        <Footer />
      </div>
    </>

  )
}

export default BundallPlumbingExpert