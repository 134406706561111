import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore } from '@fortawesome/pro-regular-svg-icons'; // Adjusted for Pro

library.add(fab);

const Footer = () => {

  const mapHtml = {
    __html: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d450817.7568417091!2d152.76270517931528!3d-28.024056319013674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa3737403dc5a66e9%3A0x6fde24f56e7cc08c!2sImperial%20PDG!5e0!3m2!1sen!2sph!4v1688610092920!5m2!1sen!2sph" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
  };
  
  return (
    
    <footer className="site-footer">
      <div className="container wide"> 
        <div className="row">
          <div className="col col-45">
            <ul className="listing">
              <li><a href="https://www.imperialpdg.com.au/">Home</a></li>
              <li><a href="https://www.imperialpdg.com.au/services/hot-water-systems/">Hot Water Systems</a></li>
              <li><a href="https://www.imperialpdg.com.au/services/plumbing-maintenance">Plumbing Maintenance</a></li>
              {/* <li><a href="https://www.imperialpdg.com.au/services/water-filter-installation">Water Filter Installation</a></li> */}
              <li><a href="#">Water Leaks</a></li>
              <li><a href="#">Bathroom & Kitchen</a></li>
              <li><a href="https://www.imperialpdg.com.au/#about-us">About Us</a></li>
              <li><a href="#section-contact">Contact Us</a></li>
            </ul>
          </div>
          <div className="col col-55">                      
            <div className="map-holder">
              <div dangerouslySetInnerHTML={mapHtml} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-10">
            <div className="socials">
              <a href="https://www.facebook.com/imperialbathroomscbr" target="_blank"><FontAwesomeIcon icon={['fab', 'fa-facebook-f']} /></a>
              <a href="https://instagram.com/imperialpdg?igshid=MzRlODBiNWFlZA==" target="_blank"><FontAwesomeIcon icon={['fab', 'fa-instagram']} /></a>
              <a href="https://www.google.com/maps/place/Imperial+Plumbing,+Drains+and+Gas/@-27.9542216,153.3693614,10z/data=!3m1!4b1!4m6!3m5!1s0xa3737403dc5a66e9:0x6fde24f56e7cc08c!8m2!3d-27.9542216!4d153.3693614!16s%2Fg%2F11kqrckq85?entry=ttu" target="_blank"><FontAwesomeIcon icon={faStore} /></a>
            </div>
          </div>
          <div className="col col-90">
            <div className="footer-notes">
              <span className='first'>©2023 Imperial PDG</span>
              <span>ABN 11 700 855 085</span>
              <a href="https://www.onlineservices.qbcc.qld.gov.au/OnlineLicenceSearch/VisualElements/ShowDetailResultContent.aspx?LicNO=15352379&licCat=LIC&name=&firstName=&searchType=Contractor&FromPage=SearchContr" target="_blank">QBCC License: 15352379</a>
              <span>Call us at <a href="tel:1300631226">1300 631 226</a></span>
            </div>
          </div>
        </div>
      </div>
	  </footer>

  )
}

export default Footer