import React from 'react'

const Section9 = ( {imgWebp, imgJpg, imgAlt, title, subtitle, desc} ) => {
  return (
    <section className="section-9">

      <div className='container full'>

        <div className='row'>
          <div className='col col-50'>
            <div className='img-holder'>
              <picture>
                <source type='image/webp' srcset={imgWebp} />
                <source type='image/jpg' srcset={imgJpg} />
                <img src={imgWebp} alt={imgAlt} draggable="false" />
              </picture>
            </div>
          </div>

          <div className='col col-50'>
            <div className='content-holder'>
              <p className='subtitle'>{subtitle}</p>
              <h3 className='title'>{title}</h3>
              <div className='desc' dangerouslySetInnerHTML={{ __html: desc }} />
            </div>
          </div>
        </div>

      </div>

    </section>
  )
}

export default Section9