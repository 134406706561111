import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

const Locations = () => {
  return (

    <div class="multi-list-holder">
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Tweed Heads</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Broadbeach Waters</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Broadwater</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Burleigh Heads</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Carrara</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Coolangatta</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Coomera</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Currumbin</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Gaven</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Helensvale</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Hope Island</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Main Beach</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Mermaid Beach</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Mudgeeraba</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Oxenford</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Paradise Point</p>                
                </div>
                <div class="multi-list-item">                   
                  {/* <a href="/gold-coast/plumber-bundall/"><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Bundall</a>                        */}
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Bundall</p>                    
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Robina</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Southport</p>                
                </div>
                <div class="multi-list-item">                  
                  <p><FontAwesomeIcon icon={faArrowRightLong} size="lg" /> Surfers Paradise</p>                
                </div>
              </div>

  )
}

export default Locations