import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const ContactForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    services: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://server.imperialpdg.com.au/api/contacts', formData);
      setFormData({
        name: '',
        email: '',
        phone: '',
        services: '',
        message: '',
      });

      // Redirect to the thank-you page after successful form submission
      navigate('/thank-you');
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const serviceOptions = [
    'Hot Water Systems',
    'Water Leaks',
    'Bathroom & Kitchen',
    'Plumbing Maintenance',
  ];

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-wrapper">

        <div className='field'>
          <label class="field-label">
             <span>Your Name *</span>
            <input type="text" name="name" value={formData.name} onChange={handleChange} required />
          </label>
        </div>

        <div className='field'>
          <label class="field-label">
          <span>Your Email *</span>
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          </label>
        </div>

        <div className='field'>
          <label class="field-label">
            <span>Your Phone *</span>
            <input type="text" name="phone" value={formData.phone} onChange={handleChange} required />
          </label>
        </div>

        <div className='field'>
          <label className="field-label">
            <span>Select services *</span>
            <select name="services" value={formData.services} onChange={handleChange} required>
              <option value="">Select a service</option>
              {serviceOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </label>
        </div>

        <div className='field'>
          <label class="field-label">
          <span>Your Message</span>
            <textarea name="message" value={formData.message} onChange={handleChange} required />
          </label>
        </div>

      </div>
      
      <button type="submit">Submit</button>

      <div className='row'>
        <div className='col col-100'>
          <h4 className='title'>Get in touch with Imperial PDG Support</h4>
        </div>
      </div>

      <div className='row'>
        <div className='col col-100'>
          <div className='button-holder'>
            <a href = 'tel:1300631226' className='btn-cust'>
              <span className='menu-icon step'>
                <span className='step-inner'>
                  <FontAwesomeIcon icon={faPhone} size='lg' color='black' />
                </span>
              </span>
              <span>Call us at 1300 631 226</span>
            </a>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col col-100'>
          <h4 className='title business-hours'>Business Hours:</h4>
        </div>
      </div>

      <div className='row'>
        <div className='col col-50 left'>
          <p className='time'>Monday</p>
          <p className='time'>Tuesday</p>
          <p className='time'>Wednesday</p>
          <p className='time'>Thursday</p>
          <p className='time'>Friday</p>
          <p className='time'>Saturday</p>
          <p className='time'>Sunday</p>
        </div>
        <div className='col col-50 right'>
          <p className='time'>7 am - 5 pm</p>
          <p className='time'>7 am - 5 pm</p>
          <p className='time'>7 am - 5 pm</p>
          <p className='time'>7 am - 5 pm</p>
          <p className='time'>7 am - 5 pm</p>
          <p className='time'>7 am - 5 pm</p>
          <p className='time'>7 am - 5 pm</p>
        </div>
      </div>

    </form>
  );
};

export default ContactForm;
