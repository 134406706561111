import React from 'react'

const Section1 = () => {
  return (
    
    <section className="section-1">
      <div className="container wide">
        <div className="row">
          <div className="col col-100">
            <div className="content-holder">
              <h2 className="title">Gold Coast Plumbers</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-50">
            <h3 className="subtitle">Imperial provides the best service for all your plumbing needs.</h3>
          </div>
          <div className="col col-50">
            <p className="desc">Industry leading Gold Coast plumbers committed to providing the highest quality work. We are a full-service plumbing company that offers honest, fair pricing, excellent customer service and only the best results by the most experienced master plumbers.</p>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Section1