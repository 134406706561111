import React from 'react';
import ReactDOM from 'react-dom/client';
import './fonts.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Homepage from './components/main/Homepage';
import HotWaterSystemsPage from './components/main/HotWaterSystemsPage';
import PlumbingMaintenancePage from './components/main/PlumbingMaintenancePage';
import ThankYouPage from './components/main/ThankYouPage';
import WaterFilterInstallationPage from './components/main/WaterFilterInstallationPage';
import WaterLeaksPage from './components/main/WaterLeaksPage';
import BathroomKitchenPlumbingPage from './components/main/BathroomKitchenPlumbingPage';
import BundallPlumbingExpert from './components/main/BundallPlumbingExpert';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <BrowserRouter>

    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/services/hot-water-systems/" element={<HotWaterSystemsPage />} />
      <Route path="/services/plumbing-maintenance/" element={<PlumbingMaintenancePage />} />
      {/* <Route path="/services/water-filter-installation/" element={<WaterFilterInstallationPage />} /> */}
      <Route path="/services/water-leaks/" element={<WaterLeaksPage />} />
      <Route path="/services/bathroom-kitchen-plumbing/" element={<BathroomKitchenPlumbingPage />} />
      <Route path="/gold-coast/plumber-bundall/" element={<BundallPlumbingExpert />} />
      <Route path="/thank-you/" element={<ThankYouPage />} />
    </Routes>

  </BrowserRouter>

);

reportWebVitals();
