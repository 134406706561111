import React from 'react'

const Section2 = () => {
  return (
    <section className="section-2">
      <div id="our-services"></div>
      <div className='container full'>
        <div className='row'>
          <div className='col col-50'>              
            <div class="flip-box hot-water-systems">
              <div class="flip-box-inner">
                <div class="flip-box-front section">
                  <div 
                    className='background-holder'  
                    role="img" 
                    aria-label="A plumber fixing a hot water system on the Gold Coast"
                  >
                    <span class="sr-only">A plumber fixing a hot water system on the Gold Coast</span>
                  </div>
                  <div className='container'>
                    <h2 className='title'>hot water systems</h2>
                  </div>
                </div>
                <div class="flip-box-back">
                  <div className='content-holder'>
                    <h2 className='title'>hot water systems</h2>
                    <p className='desc'>Experience unparalleled service with our top-notch Hot Water Systems. Whether you're after Instantaneous, Gas, or Electric Hot Water Systems, we've got the expertise to ensure your home always stays comfortably warm. And if ever you face any glitches, our prompt Hot Water System Repairs are just a call away, ensuring your peace of mind all year round.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col col-50'>             
            <div class="flip-box bathroom-kitchen">
              <div class="flip-box-inner">
                <div class="flip-box-front section">
                  <div 
                    className='background-holder'  
                    role="img" 
                    aria-label="Plumber fixing mixer tap in gold coast Kitchen"
                  >
                    <span class="sr-only">Plumber fixing mixer tap in gold coast Kitchen</span>
                  </div>
                  <div className='container'>
                    <h2 className='title'>bathroom & kitchen</h2>
                  </div>
                </div>
                <div class="flip-box-back">
                  <div className='content-holder'>
                    <h2 className='title'>bathroom & kitchen</h2>
                    <p className='desc'>Transform your Bathroom & Kitchen with our unrivalled installation services. From sinks, toilets and showers to state-of-the-art mixer taps, we excel at bringing your vision to life. Should your facilities falter, our expert Toilet Repair & Replacement, Shower Repairs, and dedicated Water Filter Servicing ensure you'll never be left high and dry. Trust in us for all your Water Filter Installation needs, delivering clean, fresh water every time.</p>                      
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col col-50'>              
            <div class="flip-box water-leaks">
              <div class="flip-box-inner">
                <div class="flip-box-front section">
                  <div 
                    className='background-holder'  
                    role="img" 
                    aria-label="A plumber fixing a water leak in Gold Coat Kitchen"
                  >
                    <span class="sr-only">A plumber fixing a water leak in Gold Coat Kitchen </span>
                  </div>
                  <div className='container'>
                    <h2 className='title'>water leaks</h2>
                  </div>
                </div>
                <div class="flip-box-back">
                  <div className='content-holder'>
                    <h2 className='title'>water leaks</h2>
                    <p className='desc'>Say goodbye to water woes with our comprehensive leak solutions. Whether you're dealing with a Burst Pipe, Water Leak, or simply a pesky Leaking Tap, our team is primed to swiftly diagnose and rectify the problem. With sophisticated Water Leak Detection technology at our disposal, we'll get your home back in shipshape, keeping it dry and damage-free.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col col-50'>             
            <div class="flip-box contact-us">
              <div class="flip-box-inner">
                <div class="flip-box-front section">
                  <div 
                    className='background-holder'  
                    role="img" 
                    aria-label="Mitch from Imperial Plumbing is standing with Gold Coast Skyline behind him"
                  >
                    <span class="sr-only">Mitch from Imperial Plumbing is standing with Gold Coast Skyline behind him</span>
                  </div>
                  <div className='container'>
                    <h2 className='title'>contact us</h2>
                  </div>
                </div>
                <div class="flip-box-back">
                  <div className='content-holder'>
                    <h2 className='title'>contact us</h2>
                    <p className='desc'>Choose our expert plumbers for a service steeped in professionalism, efficiency, and unparalleled expertise. With years of experience under our belts, we're equipped to tackle every plumbing challenge, delivering solutions that last. So, when you need reliable, high-quality plumbing services, remember - our proficient team is only a call away, ready to ensure your home functions at its best.</p>                      
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section2