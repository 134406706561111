import React from 'react';
import Nav from './Nav';
import ImperialLogoWebp from '../img/Imperial PDG logo.webp';
import ImperialLogoPng from '../img/Imperial PDG logo.webp';

const Header = () => {
  return (

    <header id="header">
      <div className='container full'>
        <div className='row'>
          <div className='col col-30'>
            <div className='site-logo'>
              <a href='/'>
                <picture>
                  <source type='image/webp' srcset={ImperialLogoWebp} />
                  <source type='image/png' srcset={ImperialLogoPng} />
                  <img src={ImperialLogoWebp} alt="Imperial PDG logo" />
                </picture>
              </a>
            </div>
          </div>
          <div className='col col-70'>
            <div className='site-nav'>
              <Nav />
            </div>
          </div>
        </div>
      </div>
    </header>

  )
}

export default Header