import React from 'react'

const Section3 = () => {
  return (
    <section className="section-3">
      <div id="about-us"></div>
      <div className='container boxed'>
        <div className='row'>
          <div className='col col-100'>
            <h2 className='title'>Why Choose Us</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col col-50'>
            <div className='content-holder'>
              <h3 className='title'>On time, Every time</h3>
              <p className='desc'>We understand the importance of punctuality, so we guarantee to arrive on time for every plumbing appointment. Our commitment to timeliness ensures that you can always rely on us for prompt and efficient service.</p>
            </div>
          </div>
          <div className='col col-50'>
            <div className='content-holder'>
              <h3 className='title'>Fixed Price Guarantee</h3>
              <p className='desc'>With our Fixed Price Guarantee, you can have peace of mind knowing that the price we quote is the price you'll pay for our plumbing services. No hidden fees or surprises, just transparent and fair pricing that you can trust.</p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col col-50'>
            <div className='content-holder'>
              <h3 className='title'>100% Customer Satisfaction</h3>
              <p className='desc'>We prioritise 100% customer satisfaction as the cornerstone of our plumbing services. Our dedicated team is committed to going above and beyond to meet and exceed your expectations. From the initial consultation to the completion of the project, we strive to ensure your utmost satisfaction, delivering reliable and top-quality plumbing solutions that leave you completely content.</p>
            </div>
          </div>
          <div className='col col-50'>
            <div className='content-holder'>
              <h3 className='title'>Highly Trained Plumbers</h3>
              <p className='desc'>Our team consists of highly trained plumbers who possess extensive knowledge and expertise in their field. With a commitment to continuous learning and professional development, our skilled plumbers stay up-to-date with the latest industry standards and best practices. When you choose us, you can be confident that competent professionals will handle your plumbing needs.</p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col col-50'>
            <div className='content-holder'>
              <h3 className='title'>Respect For Your Home </h3>
              <p className='desc'>Respecting your home is our top priority. Our team of plumbers understands the importance of treating your property with care and diligence. You can trust us to maintain a clean and tidy work environment, and we'll leave your home in the same condition as when we arrived, ensuring minimal disruption to your daily routine.</p>
            </div>
          </div>
          <div className='col col-50'>
            <div className='content-holder'>
              <h3 className='title'>Workmanship Warranty</h3>
              <p className='desc'>Our Workmanship Warranty provides confidence in our plumbing work's quality. We stand behind our craftsmanship and offer a guarantee, ensuring that if any issues arise, we will rectify them promptly and efficiently at no additional cost to you.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section3