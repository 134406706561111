import React from 'react'
import Testimonials from './Testimonials'

const Section6 = () => {
  return (
    <section className="section-6">
      <div className="container boxed">
        <div className="row">
          <div className="col col-100">	
            <h2 className="title">Testimonials</h2>
            <Testimonials />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section6