import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './css/globals.css';
import './css/contact.css';
import './css/header.css';
import './css/footer.css';
import './css/page.css';
import Header from './sections/Header';
import Footer from './sections/Footer';
import Banner from './sections/Banner';
import Cta from './sections/Cta';
import Section3 from './sections/Section3';
import Section4 from './sections/Section4';
import Section5 from './sections/Section5';
import Section6 from './sections/Section6';
import Section7 from './sections/Section7';
import Section8 from './sections/Section8';
import Section9 from './sections/Section9';
import Section10 from './sections/Section10';
import Section11 from './sections/Section11';
import Accordion from './sections/Accordion';

const PlumbingMaintenancePage = () => {

    const schema = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        'name': 'ImperialPDG', // Your site name
        'description': 'Dependable plumbing professionals trusted by Gold Coast residents. Rely on us to resolve all your domestic and commercial plumbing issues promptly and effectively',
        'address': {
          '@type': 'PostalAddress',
          'addressLocality': 'Gold Coast',
          'addressRegion': 'QLD',
          'postalCode': '9726', // Add your postal code here
          'streetAddress': 'PO Box 4916' // Add your street address here
        },
        'location': {
            '@type': 'Place',
            'name': 'ImperialPDG', // Name of your location
            'geo': {
              '@type': 'GeoCoordinates',
              'latitude': -28.01294080513038, // Your latitude
              'longitude': 153.40505834857066 // Your longitude
            }
          },
      };

  const [pageTitle, setPageTitle] = useState('Dynamic Page Title');
  const [metaDescription, setMetaDescription] = useState('This is a dynamic description for the page.');
  const [metaKeywords, setMetaKeywords] = useState('react, dynamic, meta tags');

  useEffect(() => {
    console.log('Meta Description:', metaDescription);
    console.log('Meta Keywords:', metaKeywords);
    setPageTitle('Gold Coast Plumbing Maintenance Services | Reliable & Efficient');
    setMetaDescription('Expert plumbing maintenance services in Gold Coast. From leak repairs to system upgrades, our experienced plumbers ensure your plumbing functions flawlessly.');
    setMetaKeywords('plumbing maintenance gold coast');
 }, []);

 
 useEffect(() => {
    // Update meta tags whenever metaDescription or metaKeywords change
    const helmet = Helmet.peek();
    helmet.metaTags = [
      { name: 'description', content: metaDescription },
      { name: 'keywords', content: metaKeywords }
    ];
  }, [metaDescription, metaKeywords]);

  const accordionData = [
    {
      title: 'Why is regular plumbing maintenance important?',
      content: 'Regular plumbing maintenance is crucial for ensuring the smooth operation of your plumbing system. It helps prevent minor issues from escalating into major, costly problems, extends the lifespan of your plumbing fixtures, and can improve water efficiency, ultimately saving you money on utility bills.'
    },
    {
      title: 'What does plumbing maintenance include?',
      content: 'Plumbing maintenance can include a variety of services such as leak detection and repair, drain cleaning, inspecting and maintaining water heaters, checking water pressure, inspecting pipes for corrosion or damage, and ensuring all fixtures are functioning properly.'
    },
    {
      title: 'How often should I have my plumbing system checked?',
      content: 'It`s recommended to have a professional plumber inspect your plumbing system at least once a year. However, if you have an older home or have experienced issues in the past, more frequent checks might be necessary.'
    },
    {
      title: 'Can plumbing maintenance help with smelly drains?',
      content: 'Absolutely! Regular maintenance can identify and address the causes of smelly drains, such as clogs or venting issues. Additionally, devices like Gratemates can be installed to prevent sewer smells from rising up through the drains.'
    },
    {
      title: 'What are the signs that I need plumbing maintenance?',
      content: 'Common signs include slow drains, unpleasant odours, water stains on walls or ceilings, unusually high water bills, and inconsistent water pressure. If you notice any of these, it`s time to schedule a maintenance check.'
    },
    {
      title: 'Are emergency plumbing services available in Gold Coast?',
      content: 'Yes, many plumbing services in Gold Coast offer 24/7 emergency responses for urgent plumbing issues that cannot wait for regular maintenance appointments.'
    },
    {
      title: 'Is it worth getting a plumbing maintenance plan?',
      content: 'Yes, a plumbing maintenance plan can be very beneficial. It ensures regular inspections and maintenance work, helping to prevent unexpected breakdowns and potentially saving you money in the long run.'
    },
    {
      title: 'Can plumbing maintenance save me money?',
      content: 'Definitely. By catching and fixing small issues early, you can avoid the expense of major repairs. Additionally, well-maintained plumbing systems are more efficient, reducing water and energy waste.'
    },
    {
      title: 'How do I choose the best plumbing maintenance service in Gold Coast?',
      content: 'Look for services with certified and experienced plumbers, positive customer reviews, comprehensive maintenance plans, and a commitment to customer satisfaction. Also, ensure they offer the specific services you need.'
    },
    {
      title: 'Will plumbing maintenance disrupt my daily routine?',
      content: 'Professional plumbers strive to minimise disruption. Maintenance is usually quick and non-invasive, though the duration can vary depending on the services required.'
    },
  ];
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Plumbing Maintenance Gold Coast",
              "description": "Expert plumbing maintenance services in Gold Coast. From leak repairs to system upgrades, our experienced plumbers ensure your plumbing functions flawlessly.",
              "url": "https://www.imperialpdg.com.au/",
              "image": {
                "@type": "ImageObject",
                "url": "https://www.imperialpdg.com.au/img/hot-water-system-installation-gold-coast-2.webp",
                "width": 1200,
                "height": 630
              },
              "breadcrumb": {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                      "@id": "https://www.imperialpdg.com.au/",
                      "name": "Home"
                    }
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                      "@id": "https://www.imperialpdg.com.au/services/plumbing-maintenance/",
                      "name": "Plumbing Maintenance"
                    }
                  }
                ]
              }
            }
          `}
        </script>
      </Helmet>
      <div className='plumbingmaintenance'>
        <Header />
        <Banner 
          backgroundImg={"/img/gold-coast-plumbing-maintenance-top-banner.webp"}
          backgroundAltText={"Mitch from Imperial PDG holding a toolbox posing outside"}
          title={"Gold Coast <br/><b>Plumbing Maintenance</b>"}
          subtitle={"Reliable | Efficient | Professional"}
        />
        <Cta />
        <Section9
          imgWebp={"/img/gold-coast-plumbing-maintenance-1.webp"}
          imgJpg={"/img/gold-coast-plumbing-maintenance-1.jpg"}
          imgAlt={"Plumber with a tool bag walking beside a hot water system"}
          title={"Your Premier Plumbing Maintenance Specialists in Gold Coast!"}
          subtitle={"Welcome to Imperial PDG"}
          desc={"<p class='desc'>Looking for dependable plumbing maintenance services in the Gold Coast area? Look no further! At Imperial PDG, we pride ourselves on delivering top-notch plumbing solutions tailored to the unique needs of Gold Coast residents.</p><p class='desc'>Our team of certified and experienced plumbers is equipped to handle all your plumbing maintenance needs, big or small. We've got you covered, from routine inspections and cleanings to emergency repairs.</p><h6>Our Plumbing Maintenance Services Include:</h6>   <ul><li>Regular Inspections and Assessments</li><li>Leak Detection and Repair</li><li>Pipe Cleaning and Descaling</li><li>Hot Water System Maintenance</li><li>Drain and Sewer Line Maintenance</li></ul>"}
        />
        <Section10
          imgWebp={"/img/gold-coast-plumbing-maintenance-2.webp"}
          imgJpg={"/img/gold-coast-plumbing-maintenance-2.jpg"}
          imgAlt={"Plumber with a tool bag installing a hot water system"}
          title={"Plumbing Maintenance For Smelly Drains"}
          subtitle={"Plumbing Services"}
          desc={"<p class='desc'>If you're frequently scrunching your nose due to unpleasant odours wafting from your drains, Imperial PDG has the ideal solution. Our specialised plumbing maintenance services in the Gold Coast area are crafted to tackle and eradicate persistent sewer smells that can invade your home without any obvious signs of blockages.</p><h6>Why Tolerate Sewer Smells When You Don't Have To?</h6><p class='desc'>We believe that your home should be a sanctuary devoid of the discomfort of foul odours. That's why we're pleased to use and recommend Gratemates, an innovative device expertly fitted into your floor waste. This clever solution is a barrier, preventing those stubborn sewer smells from travelling back into your bathroom or any other room.</p>"}
        />
        <Section11
          title={"FAQs"}
          subtitle={"Plumbing Maintenance on the Gold Coast"}
         />
        <div className="accordion boxed">
          {accordionData.map(({ title, content }) => (
            <Accordion title={title} content={content} />
          ))}
        </div>
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />  
        <Section8 />
        <Section7 />
        <Footer />
      </div>
    </>

  )
}

export default PlumbingMaintenancePage


