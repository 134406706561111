import React from 'react'
import BookIconWebp from '../img/book-your-service-icon.webp'
import BookIconPng from '../img/book-your-service-icon.png'
import TimeIconWebp from '../img/pick-a-time-icon.webp'
import TimeIconPng from '../img/pick-a-time-icon.png'
import ComeIconWebp from '../img/we-come-to-you-icon.webp'
import ComeIconPng from '../img/we-come-to-you-icon.png'
import PayIconWebp from '../img/pay-for-our-service-icon.webp'
import PayIconPng from '../img/pay-for-our-service-icon.png'

const Section5 = () => {
  return (
    <section className="section-5">
      <div className='container wide'>
        <div className='row'>
          <div className='col col-100'>
            <h3 className='subtitle'>Plumbing in 4 Easy Steps</h3>
            <h2 className='title'>We made plumbing easy</h2>
            <p className='desc'>Our 4,000+ plumbing and gas tasks have enriched our knowledge in the field. Customers seek an effortless encounter with their plumbers; hence we've devised a straightforward procedure for your convenience.</p>
          </div>
        </div>
        <div className='row'>
          <div className='line desktop'></div>
          <div className='col col-25'>
            <div className='content-holder'>
              <div className='img-holder'>
                <picture>
                  <source type='image/webp' srcset={BookIconWebp} />
                  <source type='image/png' srcset={BookIconPng} />
                  <img src={BookIconWebp} alt="book your service icon" draggable="false" />
                </picture>
              </div>
              <h4 className='title'>BOOK YOUR SERVICE</h4>
              <p className='desc'>Call <a href="tel:1300631226">1300 631 226</a> or schedule a booking online.</p>
            </div>
          </div>
          <div className='col col-25'>
            <div className='content-holder'>
              <div className='img-holder'>
                <picture>
                  <source type='image/webp' srcset={TimeIconWebp} />
                  <source type='image/png' srcset={TimeIconPng} />
                  <img src={TimeIconWebp} alt="pick a time icon" draggable="false" />
                </picture>
              </div>
              <h4 className='title'>PICK A TIME</h4>
              <p className='desc'>Choose a time that works for you and your schedule.</p>
            </div>
          </div>
          <div className='col col-25'>
            <div className='content-holder'>
              <div className='img-holder'>
                <picture>
                  <source type='image/webp' srcset={ComeIconWebp} />
                  <source type='image/png' srcset={ComeIconPng} />
                  <img src={ComeIconWebp} alt="we come to you icon" draggable="false" />
                </picture>
              </div>
              <h4 className='title'>WE COME TO YOU</h4>
              <p className='desc'>We’re on time, every time to fix your plumbing.</p>
            </div>
          </div>
          <div className='col col-25'>
            <div className='content-holder'>
              <div className='img-holder'>
                <picture>
                  <source type='image/webp' srcset={PayIconWebp} />
                  <source type='image/png' srcset={PayIconPng} />
                  <img src={PayIconWebp} alt="pay for our service icon" draggable="false" />
                </picture>
              </div>
              <h4 className='title'>PAY FOR OUR SERVICE</h4>
              <p className='desc'>Pay our fixed price invoice, or use our financing and save.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section5